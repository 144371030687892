import React from 'react';

import Carousel from 'react-bootstrap/Carousel';
import ExampleCarouselImage from "../assets/images/Mosquito-20Away-20banner-02-desk_03_2024.jpg";
import ExampleCarouselImage2 from "../assets/images/LG_Tele_DD_03_2024.jpg";
import ExampleCarouselImage3 from "../assets/images/Cadeux pour l'amour_03_2024.jpg";

import Showroom from '../components/Showroom/Showroom';

import { Container, Row, Col } from 'react-bootstrap';

import Accordion from 'react-bootstrap/Accordion';
import Catalogue_LG_audio from '../components/catalogue_LG/catalogue_doc/lg_audio_catalogue.pdf';
import Catalogue_LG_tv from '../components/catalogue_LG/catalogue_doc/LGTV.pdf';
import Catalogue_LG_split from '../components/catalogue_LG/catalogue_doc/lg_split_catalogue.pdf';
import Catalogue_LG_machine from '../components/catalogue_LG/catalogue_doc/lg_machine_catalogue.pdf';
import Catalogue_LG_micro from '../components/catalogue_LG/catalogue_doc/lg_micro_catalogue.pdf';
import Catalogue_LG_cuisiniere from '../components/catalogue_LG/catalogue_doc/lg_cuisiniere_catalogue.pdf';
import Catalogue_LG_lave from '../components/catalogue_LG/catalogue_doc/lg_lave_vaisselle_catalogue.pdf';
import Catalogue_LG_congelateur from '../components/catalogue_LG/catalogue_doc/lg_congelateur_catalogue.pdf';
import Catalogue_LG_ref from '../components/catalogue_LG/catalogue_doc/lg_ref_catalogue.pdf';



// Hisense

import Catalogue_Hisense_audio from '../components/catalogue_Hisense/catalogue_Hisense/audio_hisense.pdf';
import Catalogue_Hisense_tv from '../components/catalogue_Hisense/catalogue_Hisense/hisense_tv_catalogue.pdf';
import Catalogue_Hisense_split from '../components/catalogue_Hisense/catalogue_Hisense/hisense_split_catalogue.pdf';
import Catalogue_Hisense_machine from '../components/catalogue_Hisense/catalogue_Hisense/hisense_machine_catalogue.pdf';
import Catalogue_Hisense_micro from '../components/catalogue_Hisense/catalogue_Hisense/hisense_micro_catalogue.pdf';

import Catalogue_Hisense_lave from '../components/catalogue_Hisense/catalogue_Hisense/hisense_air_catalogue.pdf';
import Catalogue_Hisense_congelateur from '../components/catalogue_Hisense/catalogue_Hisense/hisense_congelateur_catalogue.pdf';
import Catalogue_Hisense_ref from '../components/catalogue_Hisense/catalogue_Hisense/hisense_ref_catalogue.pdf';

// Corredo Paco Perkins

import Catalogue_Corredo from '../components/catalogue_LG/catalogue_doc/paco/corredo_catalogue1.pdf';
import Catalogue_Perkins from '../components/catalogue_LG/catalogue_doc/paco/perkins_catalogue.pdf';
import Catalogue_Paco from '../components/catalogue_LG/catalogue_doc/paco/paco_catalogue.pdf';

//pdf

import { FaRegFilePdf } from "react-icons/fa6";



function Catalogue_LG() {
  return <>

<section className='pt-1'>
    <Carousel>
      <Carousel.Item>
        <img src={ExampleCarouselImage} text="" />
        <Carousel.Caption>
        
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img src={ExampleCarouselImage2} text=""  />
        <Carousel.Caption>
          
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img src={ExampleCarouselImage3} text="" />
        <Carousel.Caption>
         
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
    </section>

    <Container>
      <Row>
        
        <Col>
        <h1 className='text-center'>LG Catalogue</h1> <br />
        <Accordion defaultActiveKey="0" flush>
    <Accordion.Item eventKey="0">
      <Accordion.Header>Catalogue LG AUDIO  <FaRegFilePdf /></Accordion.Header>
      <Accordion.Body>
          <div>
      <iframe src={Catalogue_LG_audio} style={{'width':'100%', 'height':'800px'}} />
      </div>   
      </Accordion.Body>
    </Accordion.Item>
    <Accordion.Item eventKey="1">
      <Accordion.Header>Catalogue LG TV  <FaRegFilePdf /></Accordion.Header>
      <Accordion.Body>
      <div>
      <iframe src={Catalogue_LG_tv} style={{'width':'100%', 'height':'800px'}} />
      </div>
      </Accordion.Body>
    </Accordion.Item>
    <Accordion.Item eventKey="2">
      <Accordion.Header>Catalogue LG CLIMATISEUR  <FaRegFilePdf /></Accordion.Header>
      <Accordion.Body>
      <div>
      <iframe src={Catalogue_LG_split} style={{'width':'100%', 'height':'800px'}} />
      </div>
      </Accordion.Body>
    </Accordion.Item>
    <Accordion.Item eventKey="3">
      <Accordion.Header>Catalogue LG MICRO-ONDE  <FaRegFilePdf /></Accordion.Header>
      <Accordion.Body>
      <div>
      <iframe src={Catalogue_LG_micro} style={{'width':'100%', 'height':'800px'}} />
      </div>
      </Accordion.Body>
    </Accordion.Item>
    <Accordion.Item eventKey="4">
      <Accordion.Header>Catalogue LG MACHINE A LAVER  <FaRegFilePdf /></Accordion.Header>
      <Accordion.Body>
      <div>
      <iframe src={Catalogue_LG_machine} style={{'width':'100%', 'height':'800px'}} />
      </div>
      </Accordion.Body>
    </Accordion.Item>
    <Accordion.Item eventKey="5">
      <Accordion.Header>Catalogue LG REFRIGERATEUR   <FaRegFilePdf /></Accordion.Header>
      <Accordion.Body>
      <div>
      <iframe src={Catalogue_LG_ref} style={{'width':'100%', 'height':'800px'}} />
      </div>
      </Accordion.Body>
    </Accordion.Item>
    <Accordion.Item eventKey="6">
      <Accordion.Header>Catalogue LG CONGELATEUR  <FaRegFilePdf /></Accordion.Header>
      <Accordion.Body>
      <div>
      <iframe src={Catalogue_LG_congelateur} style={{'width':'100%', 'height':'800px'}} />
      </div>
      </Accordion.Body>
    </Accordion.Item>
    <Accordion.Item eventKey="7">
      <Accordion.Header>Catalogue LG CUISINIERE  <FaRegFilePdf /></Accordion.Header>
      <Accordion.Body>
      <div>
      <iframe src={Catalogue_LG_cuisiniere} style={{'width':'100%', 'height':'800px'}} />
      </div>
      </Accordion.Body>
    </Accordion.Item>
    <Accordion.Item eventKey="8">
      <Accordion.Header>Catalogue LG LAVE VAISSELLE  <FaRegFilePdf /></Accordion.Header>
      <Accordion.Body>
      <div>
      <iframe src={Catalogue_LG_lave} style={{'width':'100%', 'height':'800px'}} />
      </div>
      </Accordion.Body>
    </Accordion.Item>
  </Accordion>

        </Col>
      </Row>
    </Container>

  
    <Container>
      <Row>
        <Col>
        <h1 className='text-center'>Hisense Catalogue</h1> <br />
        <Accordion defaultActiveKey="0" flush>
    <Accordion.Item eventKey="0">
      <Accordion.Header>Catalogue Hisense AUDIO  <FaRegFilePdf />  </Accordion.Header>
      <Accordion.Body>
          <div>
      <iframe src={Catalogue_Hisense_audio} style={{'width':'100%', 'height':'800px'}} />
      </div>   
      </Accordion.Body>
    </Accordion.Item>
    <Accordion.Item eventKey="1">
      <Accordion.Header>Catalogue Hisense TV  <FaRegFilePdf /></Accordion.Header>
      <Accordion.Body>
      <div>
      <iframe src={Catalogue_Hisense_tv} style={{'width':'100%', 'height':'800px'}} />
      </div>
      </Accordion.Body>
    </Accordion.Item>
    <Accordion.Item eventKey="2">
      <Accordion.Header>Catalogue Hisense CLIMATISEUR  <FaRegFilePdf /></Accordion.Header>
      <Accordion.Body>
      <div>
      <iframe src={Catalogue_Hisense_split} style={{'width':'100%', 'height':'800px'}} />
      </div>
      </Accordion.Body>
    </Accordion.Item>
    <Accordion.Item eventKey="3">
      <Accordion.Header>Catalogue Hisense MICRO-ONDE  <FaRegFilePdf /></Accordion.Header>
      <Accordion.Body>
      <div>
      <iframe src={Catalogue_Hisense_micro} style={{'width':'100%', 'height':'800px'}} />
      </div>
      </Accordion.Body>
    </Accordion.Item>
    <Accordion.Item eventKey="4">
      <Accordion.Header>Catalogue Hisense MACHINE A LAVER  <FaRegFilePdf /></Accordion.Header>
      <Accordion.Body>
      <div>
      <iframe src={Catalogue_Hisense_machine} style={{'width':'100%', 'height':'800px'}} />
      </div>
      </Accordion.Body>
    </Accordion.Item>
    <Accordion.Item eventKey="5">
      <Accordion.Header>Catalogue Hisense REFRIGERATEUR  <FaRegFilePdf /></Accordion.Header>
      <Accordion.Body>
      <div>
      <iframe src={Catalogue_Hisense_ref} style={{'width':'100%', 'height':'800px'}} />
      </div>
      </Accordion.Body>
    </Accordion.Item>
    <Accordion.Item eventKey="6">
      <Accordion.Header>Catalogue Hisense CONGELATEUR  <FaRegFilePdf /></Accordion.Header>
      <Accordion.Body>
      <div>
      <iframe src={Catalogue_Hisense_congelateur} style={{'width':'100%', 'height':'800px'}} />
      </div>
      </Accordion.Body>
    </Accordion.Item>
    <Accordion.Item eventKey="8">
      <Accordion.Header>Catalogue Hisense AIR PURIFIE</Accordion.Header>
      <Accordion.Body>
      <div>
      <iframe src={Catalogue_Hisense_lave} style={{'width':'100%', 'height':'800px'}} />
      </div>
      </Accordion.Body>
    </Accordion.Item>
  </Accordion>
        </Col>
      </Row>
    </Container>

    <Container>
        <Row>
            <Col>
            <h1 className='text-center'>Corredo, Paco & Perkins Catalogue</h1> <br />
            <Accordion defaultActiveKey="0" flush>
      <Accordion.Item eventKey="0">
        <Accordion.Header>Catalogue Corredo  <FaRegFilePdf /></Accordion.Header>
        <Accordion.Body>
            <div>
        <iframe src={Catalogue_Corredo} style={{'width':'100%', 'height':'800px'}} />
        </div>   
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>Catalogue Perkins  <FaRegFilePdf /></Accordion.Header>
        <Accordion.Body>
        <div>
        <iframe src={Catalogue_Perkins} style={{'width':'100%', 'height':'800px'}} />
        </div>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header>Catalogue Paco  <FaRegFilePdf /></Accordion.Header>
        <Accordion.Body>
        <div>
        <iframe src={Catalogue_Paco} style={{'width':'100%', 'height':'800px'}} />
        </div>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
            </Col>
        </Row>
      </Container>
    
    
 
  <section className='pt-1 mt-5'>
        <Showroom />
    </section>
  
</>
}

export default Catalogue_LG